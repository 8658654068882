import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sendYmEvent } from '../utils/metrics';

import { InfoBlock } from './InfoBlock';

const BannerWrapper: React.FC = () => {
  const { t } = useTranslation();

  const showInfoItem = localStorage.getItem('showInfo');

  const [infoDisplayed, setInfoDisplayed] = useState(
    showInfoItem ? JSON.parse(showInfoItem).showInfo : true,
  );

  const toggleDisplayInfo = () => {
    setInfoDisplayed(!infoDisplayed);
    localStorage.setItem(
      'showInfo',
      JSON.stringify({ showInfo: !infoDisplayed }),
    );
    if (infoDisplayed) {
      sendYmEvent('infoBlockHide');
    } else {
      sendYmEvent('infoBlockOpen');
    }
  };

  return (
    <>
      <div
        className={
          infoDisplayed
            ? 'info-block-switch-container'
            : 'info-block-switch-container-hidden'
        }
        style={{
          width: '100%',
          height: infoDisplayed ? 0 : '18px',
          zIndex: 102,
          display: 'flex',
          gap: '8px',
          justifyContent: 'flex-end',
          padding: '0px 20px 0px 20px',
          backgroundColor: 'rgb(245, 245, 245)',
        }}
      >
        <div
          style={{
            width: '90px',
            height: '24px',
            backgroundColor: 'white',
            borderRadius: '0px 0px 5px 5px',
            boxShadow: '0px 4px 4px rgb(0, 0, 0, 0.05)',
            display: 'flex',
            justifyContent: 'center',
          }}
          onClick={toggleDisplayInfo}
        >
          {infoDisplayed ? (
            <div style={{ cursor: 'pointer', zIndex: 30000 }}>
              <span
                style={{
                  color: 'rgb(0, 112, 197)',
                  fontSize: '12px',
                  marginRight: '4px',
                }}
              >
                {t('main.hideInfo')}
              </span>
              <CaretUpOutlined style={{ color: 'rgb(0, 112, 197)' }} />
            </div>
          ) : (
            <div style={{ cursor: 'pointer', zIndex: 30000 }}>
              <span
                style={{
                  color: 'grey',
                  fontSize: '12px',
                  marginRight: '4px',
                }}
              >
                {t('main.showInfo')}
              </span>
              <CaretDownOutlined style={{ color: 'grey' }} />
            </div>
          )}
        </div>
      </div>
      <InfoBlock show={infoDisplayed} />
    </>
  );
};

export { BannerWrapper };
