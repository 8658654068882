import './toolForm.css';
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { notification } from 'antd';
import { Button, Card, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '../providers/AuthProvider';
import { useApi } from '../providers/ServerApiProvider';
import { getPricingUrl } from '../routes/apiRoutes';
import { appRoutes } from '../routes/appRoutes';
import { toolBoxStore } from '../store/store';

import { getWidget } from './customUIs/customUIhelper';
import { BboxMapWidgetInput } from './customUIs/widgets/BboxMapWidget.tsx';
import { BoolInput, NumberInput, StringInput, UploadInput } from './formInputs';

import type { toolInput } from '../interfaces';
import type { ToolInfo } from '../store/store';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const notificationIconMapping: Record<NotificationType, JSX.Element> = {
  success: <CheckOutlined style={{ color: 'green' }} />,
  error: <CloseOutlined style={{ color: 'red' }} />,
  info: <InfoCircleOutlined />,
  warning: <InfoCircleOutlined />,
};

const getNotificationIcon = (type: NotificationType): JSX.Element => {
  const icon = notificationIconMapping[type];
  if (!icon) {
    console.warn('wrong notification type: ', type);
    return <InfoCircleOutlined />;
  }
  return icon;
};

const showOperationNotification = (
  message: string = '',
  description: string = '',
  type: NotificationType,
) => {
  notification.info({
    type: type,
    message: message,
    description: description,
    placement: 'topRight',
    icon: getNotificationIcon(type),
  });
};

const Extra: React.FC<{ text: string }> = ({ text }) => {
  const extra = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (extra.current) extra.current.innerHTML = text;
  });

  return (
    <div
      ref={extra}
      style={{
        marginBottom: '6px',
        marginTop: '4px',
        fontSize: 12,
        textAlign: 'left',
        overflowWrap: 'anywhere',
      }}
    >
      {text}
    </div>
  );
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const inputMapping = {
  string: StringInput,
  int: NumberInput,
  float: NumberInput,
  boolean: BoolInput,
  file: UploadInput,
};

const getInputComponent = (input: toolInput) => {
  // testing custom input!
  if (input.name === 'bbox') {
    return BboxMapWidgetInput;
  }

  if (input.widget !== null) {
    const component = getWidget(input.widget);
    return component;
  }

  const component = inputMapping[input.type];
  if (!component) {
    return StringInput;
  }
  return component;
};

const prepareStringToNumberParsing = (value: string) => {
  return value.replace(',', '.');
};

const normalizeFieldsValueseforeSubmit = (
  fieldsValues: any,
  inputs: toolInput[],
) => {
  const normalizedProps: any = {};

  // for now it processes only float
  Object.keys(fieldsValues).forEach((key: keyof typeof fieldsValues) => {
    const input = inputs.find((i: toolInput) => i.name === key);
    if (input?.name === key) {
      if (input.type === 'float') {
        normalizedProps[key] = fieldsValues[key]
          ? prepareStringToNumberParsing(fieldsValues[key])
          : undefined;
      }
    }
  });
  return { ...fieldsValues, ...normalizedProps };
};

const ToolForm: React.FC<{
  inputs: toolInput[];
  toolId: string;
  openDocs: () => void;
  // isEnabled?: boolean | undefined;
}> = observer(
  ({
    inputs,
    toolId,
    openDocs,
    //  isEnabled
  }) => {
    const { t, i18n } = useTranslation();
    const auth = useAuth();
    const navigate = useNavigate();
    const serverApi = useApi();
    const [searchParams, setSearchParams] = useSearchParams();

    const [pending, setPending] = useState(true);
    const [runEnabled, setRunEnabled] = useState<boolean | undefined>(false);

    const { tools } = toolBoxStore;

    const toolData = tools.find((i: ToolInfo) => i.operation_id === toolId);

    const [form] = Form.useForm();

    useEffect(() => {
      const isRunEnabled = toolData?.is_free
        ? true
        : toolBoxStore.isUserPremium || toolBoxStore.deployMode === 'onpremise';
      setRunEnabled(isRunEnabled);

      const setInitialValue = (input: toolInput) => {
        if (searchParams.has(input.name)) {
          if (input.type === 'boolean') {
            form.setFieldValue(
              input.name,
              searchParams.get(input.name) === 'true',
            );
          } else {
            form.setFieldValue(input.name, searchParams.get(input.name));
          }
        } else {
          if (input.type === 'boolean' && !form.getFieldValue(input.name)) {
            form.setFieldValue(input.name, false);
          }
        }
      };

      inputs.forEach(setInitialValue);
      setPending(false);
    }, [form, inputs, searchParams, toolData?.is_free]);

    const execute = async () => {
      try {
        if (toolId) {
          setPending(true);

          const normalizedFormData = normalizeFieldsValueseforeSubmit(
            form.getFieldsValue(),
            inputs,
          );

          await serverApi.postExecuteTool(toolId, normalizedFormData);
          setPending(false);
        }
        form.resetFields();
        navigate(appRoutes.ordersPage);

        //@ts-ignore
      } catch (error: Error | AxiosError) {
        setPending(false);
        console.log(error);
        const errorDescription = error.response
          ? error.response.data
          : t('operation.otherError');

        showOperationNotification(
          t('operation.executeError'),
          errorDescription,
          'error',
        );
      }
    };

    form.submit = () => {
      form
        .validateFields()
        .then(() => {
          execute();
        })
        .catch(() => {
          // console.log('err', err);
        });
    };

    const validateMessages = {
      required: (
        <>
          <span>{t('operation.validation.isRequiredMainStart')}</span>
          <i
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setTimeout(() => {
                openDocs();
              }, 100);
            }}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            {t('operation.validation.isRequiredLinkText')}
          </i>
          <span>{t('operation.validation.isRequiredMainEnd')}</span>
        </>
      ),
    };

    const customizeRequiredMark = (
      label: React.ReactNode,
      { required }: { required: boolean },
    ) => (
      <div
        style={{
          display: 'flex',
        }}
      >
        <span></span> {/* placeholder for alignment*/}
        <span style={{ maxWidth: 'fit-content', display: 'block' }}>
          {required && <span style={{ color: 'red' }}>*</span>} {label}
        </span>
      </div>
    );

    return (
      <>
        <Form
          form={form}
          //@ts-ignore
          validateMessages={validateMessages} // ReactNode actually works
          disabled={!runEnabled}
          {...formItemLayout}
          name="trigger"
          layout="horizontal"
          labelWrap={true}
          requiredMark={customizeRequiredMark}
          autoComplete="on"
          style={{
            padding: '18px 12px 0px 12px',
            maxWidth: '1100px',
            marginTop: '16px',
            borderRadius: '5px',
          }}
        >
          {inputs &&
            inputs.map((input) => {
              const InputComponent = getInputComponent(input);
              return (
                <Form.Item
                  label={input.title}
                  name={input.name}
                  style={{
                    // marginBottom: '4px',
                    maxWidth: '800px',
                  }}
                  key={input.name}
                  hasFeedback={false}
                  required={input.required && input.type !== 'boolean'}
                  rules={[{ required: input.required }]}
                  validateTrigger="onBlur"
                  extra={<Extra text={input.description} />}
                >
                  <InputComponent
                    input={input}
                    form={form}
                    setPending={setPending}
                    onValueChange={(name: string) => {
                      form.validateFields({ dirty: true });
                      setSearchParams((params) => {
                        params.delete(name);
                        return params;
                      });
                    }}
                  />
                </Form.Item>
              );
            })}
          {runEnabled && auth.isLoggedIn() && (
            <Form.Item
              {...tailFormItemLayout}
              style={{
                marginTop: '8px',
              }}
            >
              <Button
                disabled={pending}
                icon={pending && <LoadingOutlined />}
                size="large"
                type="primary"
                htmlType="submit"
              >
                {t('operation.submit')}
              </Button>
            </Form.Item>
          )}

          {!runEnabled &&
            !pending &&
            toolBoxStore?.deployMode !== 'onpremise' && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a href={getPricingUrl(i18n.language)}>
                  <Card
                    className="premium-card"
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                      {t('operation.needPremium')}
                    </span>
                    <span className="premium-chip">Premium</span>
                  </Card>
                </a>
              </div>
            )}

          {runEnabled &&
            !pending &&
            !auth.isLoggedIn() &&
            toolBoxStore?.deployMode !== 'onpremise' && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a
                  onClick={() => {
                    const params = window.location.search;
                    if (params) {
                      window.localStorage.setItem(
                        'pre_redirect_params',
                        params,
                      );
                    }
                  }}
                  href={serverApi.getLoginRedirectUrl(
                    `/${appRoutes.toolPage}/${toolId}`,
                  )}
                >
                  <Card
                    className="premium-card"
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                      {t('operation.needRegister')}
                    </span>
                  </Card>
                </a>
              </div>
            )}

          {!auth.isLoggedIn() &&
            !pending &&
            toolBoxStore.deployMode === 'onpremise' && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a
                  onClick={() => {
                    const params = window.location.search;
                    if (params) {
                      window.localStorage.setItem(
                        'pre_redirect_params',
                        params,
                      );
                    }
                  }}
                  href={serverApi.getLoginRedirectUrl(
                    `${window.location.origin}/${appRoutes.toolPage}/${toolId}`,
                  )}
                >
                  <Card
                    className="premium-card"
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                      {t('operation.onpremiseNeedLogin')}
                    </span>
                  </Card>
                </a>
              </div>
            )}
        </Form>
      </>
    );
  },
);

// OperationForm.displayName = 'OperationForm';

export { ToolForm };
