import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FavTagButton: React.FC<{
  favoriteSelected: boolean;
  handleFavChange: (checked: boolean) => void;
}> = ({ favoriteSelected, handleFavChange }) => {
  const { t } = useTranslation();

  const favTag = {
    id: 999999,
    name: t('main.tags.favourite'),
  };

  const { CheckableTag } = Tag;

  return (
    <CheckableTag
      className="fav-tag"
      style={{
        alignSelf: 'center',
        paddingLeft: '4px',
      }}
      checked={favoriteSelected}
      onChange={handleFavChange}
    >
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          gap: '2px',
        }}
      >
        {favoriteSelected ? (
          <BookmarkIcon
            style={{
              fontSize: '14px',
              color: favoriteSelected ? 'white' : '#0070c5',
              alignSelf: 'center',
            }}
          />
        ) : (
          <BookmarkBorderIcon
            style={{
              fontSize: '14px',
              color: favoriteSelected ? 'white' : '#0070c5',
              alignSelf: 'center',
            }}
          />
        )}
        {favTag.name}
      </div>
    </CheckableTag>
  );
};

export { FavTagButton };
