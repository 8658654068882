import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Button, Form, Grid, Input, Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showNotification } from '../../customNotifications';
import { useApi } from '../../providers/ServerApiProvider';
import { toolBoxStore } from '../../store/store';

import type { Order } from '../../pages/Orders';

export const SupportButton: React.FC<{ order: Order }> = ({ order }) => {
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const { email } = toolBoxStore.user;

  const serverApi = useApi();

  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const [pending, setPending] = useState(false);

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const showSupportModal = () => {
    setSupportIsModalOpen(true);
  };

  const handleSupportCancel = () => {
    setSupportIsModalOpen(false);
  };

  form.submit = async () => {
    try {
      setPending(true);
      const comment = form.getFieldValue('userComment') || '';
      await serverApi.postSupport(order.guid, comment);
      showNotification(
        t('orders.contactSupportSuccessHeader'),
        t('orders.contactSupportSuccessBody'),
        CheckOutlined,
        'green',
      );
      setPending(false);
    } catch (error) {
      console.log('Could not send support mesaage', error);
      setPending(false);
      showNotification(t('orders.contactSupportError'), '', CloseOutlined);
    }
  };

  return toolBoxStore.deployMode === 'cloud' ? (
    <>
      <Button
        icon={!screens.md && <SupportAgentIcon />}
        onClick={showSupportModal}
        type="primary"
      >
        {screens.md && t('orders.contactSupport')}
      </Button>
      <Modal
        width="800px"
        title={
          <div
            style={{
              paddingRight: '22px',
              fontWeight: 500,
              fontSize: '18px',
              display: 'flex',
              gap: '20px',
            }}
          >
            <SupportAgentIcon
              style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }}
            />
            <div>
              <span style={{ display: 'block' }}>
                {t('orders.contactSupportLabel')}
              </span>
              <span style={{ display: 'block' }}>{order.guid}</span>
            </div>
          </div>
        }
        open={isSupportModalOpen}
        onCancel={handleSupportCancel}
        footer={null}
      >
        <p
          style={{
            paddingLeft: '0px',
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.65)',
          }}
        >
          {t('orders.contactSupportDescription', { email })}
        </p>
        <Form
          name="contactSupport"
          form={form}
          disabled={pending}
          layout="vertical"
          autoComplete="on"
          onSubmitCapture={() => {
            setSupportIsModalOpen(false);
          }}
        >
          <Form.Item
            label={
              <span style={{ fontSize: '16px', marginBottom: '4px' }}>
                {t('orders.contactSupportTextArealabel')}
              </span>
            }
            name="userComment"
          >
            <TextArea allowClear style={{ fontSize: '16px' }} rows={4} />
          </Form.Item>

          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 0,
              marginTop: '12px',
            }}
          >
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={pending}
            >
              {t('orders.contactSupportOk')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  ) : null;
};
