import './userBlock.css';

import {
  CaretDownOutlined,
  LogoutOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, Grid, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { useAuth } from '../providers/AuthProvider';
import { useApi } from '../providers/ServerApiProvider';
import { cloudAuthProfileUrl } from '../routes/apiRoutes';
import { appRoutes } from '../routes/appRoutes';
import { toolBoxStore } from '../store/store';
import { getAvatarUrl } from '../utils/misc';

import type { MenuProps } from 'antd';

const { useBreakpoint } = Grid;

const LoginBtn: React.FC = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { getLoginRedirectUrl } = useApi();

  const isProd = process.env.NODE_ENV === 'production';

  const location = useLocation();

  return isProd ? (
    <Button
      type="link"
      target="_self"
      onClick={() => {
        const params = window.location.search;
        if (params) {
          window.localStorage.setItem('pre_redirect_params', params);
        }
      }}
      href={getLoginRedirectUrl(location.pathname)}
    >
      <span style={{ fontWeight: 500, color: '' }}>
        {t('navigation.login')}
      </span>
    </Button>
  ) : (
    <Button
      type="link"
      target="_self"
      onClick={() => {
        const params = window.location.search;
        if (params) {
          window.localStorage.setItem('pre_redirect_params', params);
        }
        auth.logIn();
      }}
    >
      {/* <Button type="link" href={getLoginRedirectUrl(location.pathname)}> */}
      <span style={{ fontWeight: 500, color: '' }}>
        {t('navigation.login')}
      </span>
    </Button>
  );
};

const Profile: React.FC = observer(() => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const { getLogoutRedirectUrl } = useApi();
  const [gravatarUrl, setGravatarUrl] = useState<string | undefined>();

  const isProd = process.env.NODE_ENV === 'production';

  const username = toolBoxStore.userName;

  useEffect(() => {
    const fetchAvatar = async () => {
      const { email } = toolBoxStore.user;

      if (email) {
        const url = await getAvatarUrl(email);
        if (url) {
          setGravatarUrl(url);
        }
      }
    };
    fetchAvatar();
  }, []);

  const profileMenuItem = {
    label: (
      <a
        className="a-profile-menu-item"
        target="_self"
        rel="noopener noreferrer"
        href={cloudAuthProfileUrl}
      >
        {/* {gravatar? <img></img>} */}
        <UserOutlined style={{ fontSize: 16 }} /> &nbsp;&nbsp;{' '}
        {t('navigation.profile')}
      </a>
    ),
    key: '0',
  };

  const logoutMenuItem = {
    label: isProd ? (
      <Button
        className="a-profile-menu-item"
        rel="noopener noreferrer"
        type="link"
        target="_self"
        href={getLogoutRedirectUrl()}
        onClick={() => {
          auth.logOut();
        }}
      >
        <LogoutOutlined style={{ fontSize: 16 }} /> &nbsp;&nbsp;{' '}
        {t('navigation.logout')}
      </Button>
    ) : (
      <Button
        className="a-profile-menu-item"
        rel="noopener noreferrer"
        type="link"
        target="_self"
        href={getLogoutRedirectUrl()}
        onClick={() => {
          auth.logOut();
          navigate(appRoutes.mainPage);
        }}
      >
        <LogoutOutlined style={{ fontSize: 16 }} /> &nbsp;&nbsp;{' '}
        {t('navigation.logout')}
      </Button>
    ),
    key: '1',
  };

  const profileDropDownItems: MenuProps['items'] =
    toolBoxStore.deployMode === 'onpremise'
      ? [logoutMenuItem]
      : [profileMenuItem, logoutMenuItem];

  const screens = useBreakpoint();
  return (
    <>
      <Dropdown
        overlayStyle={{ zIndex: 10000 }}
        menu={{ items: profileDropDownItems }}
        arrow={true}
        trigger={['click']}
      >
        <a className="a-profile" onClick={(e) => e.preventDefault()}>
          <Avatar
            style={{ marginBottom: '3px' }}
            icon={
              gravatarUrl ? (
                <img src={gravatarUrl} alt="avatar" />
              ) : (
                <UserOutlined />
              )
            }
          />
          {screens.md && (
            <>
              &nbsp;&nbsp;{username} <CaretDownOutlined />
            </>
          )}
        </a>
      </Dropdown>
    </>
  );
});

const Tasks: React.FC<{ updated: boolean }> = () => {
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const { t } = useTranslation();

  return (
    // <Badge dot={updated}>

    <a href={appRoutes.ordersPage} onClick={(e) => e.preventDefault()}>
      <Button
        onClick={() => {
          navigate(appRoutes.ordersPage);
        }}
        type="primary"
        icon={<UnorderedListOutlined style={{ fontSize: 16 }} />}
      >
        {screens.sm && <span>{t('navigation.orders')}</span>}
      </Button>
    </a>

    // </Badge>
  );
};

const UserBlock: React.FC = observer(() => {
  const auth = useAuth();
  const isAdmin = toolBoxStore.user.isAdmin;
  const { t } = useTranslation();

  return (
    <div
      style={{
        height: '100%',
        marginRight: '15px',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '12px',
      }}
    >
      {auth.isLoggedIn() ? (
        <>
          <Tasks updated={false} />
          {isAdmin && (
            <Tooltip title={t('navigation.admin')} mouseEnterDelay={0.3}>
              <a href={'/admin/'} target="_blanc">
                <Button
                  type="primary"
                  icon={<SettingOutlined style={{ fontSize: 16 }} />}
                />
              </a>
            </Tooltip>
          )}
          <Profile />
        </>
      ) : (
        <LoginBtn />
      )}
    </div>
  );
});

export { UserBlock };
