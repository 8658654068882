import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { appRoutes } from '../routes/appRoutes';

const RedirectToT: React.FC = () => {
  const navigate = useNavigate();
  const { toolId } = useParams();

  useEffect(() => {
    navigate(`/${appRoutes.toolPage}/${toolId}${window.location.search}`);
  });

  return <div></div>;
};

export { RedirectToT };
