import {
  action /*, autorun */,
  computed,
  makeObservable,
  observable,
} from 'mobx';

export type ToolInfo = {
  id: number;
  operation_id: string;
  name: string;
  description: string;
  is_dev: boolean;
  is_free?: boolean;
  is_favorite?: boolean;
  tags: number[];
  ngw_client_id: string | null;
  is_custom_ui?: boolean;
};

export type appConfig = {
  deploy?: 'cloud' | 'onpremise';
  oauth_urls_prefix: string;
  language_pinned?: 'ru' | 'en';
  google_analytics_id?: string;
  yandex_metrika_id?: string;
  sentry_dsn?: string;
  chatra_id?: string;
  chatra_group_id?: string;
  ru_custom_tool_request_form_link?: string;
  en_custom_tool_request_form_link?: string;
  toolbox_expert_iframe_src?: string;
};

export type TagInfo = {
  id: number;
  name: string;
  icon?: string;
  tools?: number[];
  color?: string;
};

export type User = {
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  type: 'dev' | 'default';
  isPremium?: boolean;
  authorized_ngw: string[];
  isAdmin?: boolean;
};

class ToolBoxStore {
  appConfig: appConfig = {
    deploy: 'cloud',
    oauth_urls_prefix: '',
  };
  user: User = { type: 'dev', authorized_ngw: [] };
  tags: TagInfo[] = [];
  tools: ToolInfo[] = [];

  constructor() {
    makeObservable(this, {
      appConfig: observable,
      user: observable,
      setUserName: action,
      setUserPremium: action,
      setUserData: action,
      resetUser: action,
      tools: observable,
      tags: observable,
      tagsList: computed,
      isUserPremium: computed,
      toolsCount: computed,
      addTool: action,
      updateToolsList: action,
      updateTagsList: action,
      setFavorite: action,
      setAppConfig: action,
      oauthPrefix: computed,
      deployMode: computed,
    });

    // this.tools = [];
    // autorun(() => console.log(this.tools.length));
  }

  setAppConfig(config: appConfig) {
    this.appConfig = {
      ...config,
    };
  }

  get oauthPrefix() {
    return this.appConfig.oauth_urls_prefix || null;
  }

  get deployMode() {
    return this.appConfig.deploy;
  }

  setUserName(username: string) {
    this.user.username = username;
  }

  setUserPremium(isPremium: boolean) {
    this.user.isPremium = isPremium;
  }

  setUserData(userData: Partial<User>) {
    this.user = { ...this.user, ...userData };
  }

  resetUser() {
    this.user = { type: 'default', authorized_ngw: [] };
  }

  get userName() {
    return this.user.username;
  }

  get isUserPremium() {
    return this.user.isPremium;
  }

  get tagsList() {
    return this.tags;
  }

  get toolsCount() {
    return this.tools.length;
  }

  addTool(tool: ToolInfo) {
    this.tools.push(tool);
  }

  updateToolsList(tools: ToolInfo[]) {
    this.tools = tools;
  }

  setFavorite(operation_id: string, favorite: boolean) {
    const tool = this.tools.find((tool: ToolInfo) => {
      return tool.operation_id === operation_id;
    });

    if (tool) {
      this.tools = this.tools.map((tool: ToolInfo) => {
        if (tool.operation_id === operation_id) {
          return { ...tool, is_favorite: favorite };
        } else {
          return tool;
        }
      });
    }
  }

  updateTagsList(tags: TagInfo[]) {
    this.tags = tags;
  }
}

const toolBoxStore = new ToolBoxStore();

export { toolBoxStore };
