import { CloseOutlined } from '@ant-design/icons';
import { ConfigProvider, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { RemoveTrailingSlash } from './RemoveTrailingSlash.js';
import { FooterContent } from './components/FooterContent';
import { HeaderContent } from './components/HeaderContent';
import { NoData } from './components/NoData';
import { SpinnerPage } from './components/SpinnerPage';
import { supportedLocales } from './config';
import { showNotification } from './customNotifications';
import { About } from './pages/About';
import { Main } from './pages/Main';
import { NotFound } from './pages/NotFound';
import { Orders } from './pages/Orders';
import { Pricing } from './pages/Pricing';
import { RedirectToT } from './pages/RedirectToT.js';
import { ToolPage } from './pages/ToolPage';
import { useAuth } from './providers/AuthProvider';
import { useApi } from './providers/ServerApiProvider';
import { appRoutes } from './routes/appRoutes';
import { toolBoxStore } from './store/store';
import { initChatra, updateChatraUser } from './utils/chatraService';
import { sendYmEvent } from './utils/metrics.js';

const { Header, Content, Footer } = Layout;

const ProtectedRoute = () => {
  const auth = useAuth();
  return auth.isLoggedIn() ? (
    <Outlet />
  ) : (
    <Navigate to={appRoutes.mainPage} replace />
  );
};

const View: React.FC = () => {
  const auth = useAuth();
  const serverApi = useApi();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loginOnLoad() {
      try {
        if (!auth.isLoggedIn()) {
          if (process.env.NODE_ENV === 'production') {
            try {
              auth.logIn();
              processUserProfile();
            } catch (error) {
              console.log('not logged in but maybe its ok', error);
            }
            return;
          }
        } else if (auth.isLoggedIn()) {
          processUserProfile();
          if (toolBoxStore?.deployMode !== 'onpremise') {
            sendYmEvent('authed_user_visit');
          }
        }
      } catch (error: any) {
        if (error.code && error.code === 'ECONNABORTED') {
          console.log('request aborted');
        } else {
          console.error(
            'An error occurred during login and profile retrieval:',
            error,
          );
        }
      }
    }

    async function processUserProfile() {
      try {
        const resp = await auth.getProfile();
        if (!resp || !resp.data.is_authenticated) {
          auth.logOut();
          await auth.logIn();
        } else if (resp.data.username) {
          const {
            username,
            email,
            // first_name,
            // last_name,
            is_privileged,
            is_admin,
            authorized_ngw,
          } = resp.data;

          toolBoxStore.setUserData({
            username,
            email,
            // firstName: first_name,
            // lastName: last_name,
            isPremium: !!is_privileged,
            isAdmin: is_admin,
            authorized_ngw: authorized_ngw,
          });

          if (
            toolBoxStore?.deployMode !== 'onpremise' &&
            process.env.NODE_ENV === 'production'
          ) {
            updateChatraUser(username, email);
          }

          if (i18n.language !== resp.data.locale) {
            i18n.changeLanguage(resp.data.locale);
          }
        } else {
          console.warn('Could not get username, response is: ', resp);
        }
      } catch (error: any) {
        if (error.code && error.code === 'ECONNABORTED') {
          console.log('request aborted');
        } else {
          console.error(
            'An error occurred while processing the user profile:',
            error,
          );
        }
      }
    }

    async function fetchData() {
      try {
        const respTools = await serverApi.getToolsList();
        const respTags = await serverApi.getTagsList();

        if (!respTags.data.data || !respTools.data.data) {
          showNotification(t('main.otherError'), '', CloseOutlined);
          console.log('wrong startup data!');
        }

        const toolsFromApi = respTools.data.data;
        const extendedWithTestTools = [...toolsFromApi];
        toolBoxStore.updateToolsList(extendedWithTestTools);

        const tags = respTags.data.data;
        toolBoxStore.updateTagsList(tags);

        setLoading(false);
      } catch (error: any) {
        if (error.code && error.code === 'ECONNABORTED') {
          console.log('request aborted');
        } else {
          showNotification(t('main.otherError'), '', CloseOutlined);
          console.log('error loadind startup data');
        }
        setLoading(false);
      }
    }

    if (toolBoxStore?.deployMode !== 'onpremise') {
      const locale = supportedLocales.includes(i18n.language)
        ? i18n.language
        : 'en';

      if (process.env.NODE_ENV === 'production') initChatra(locale);
    }

    loginOnLoad();
    fetchData();
  }, [auth, i18n, serverApi, t]);

  const location = useLocation();
  const isPageWithTab = () => {
    return (
      location.pathname === appRoutes.ordersPage ||
      !Object.values(appRoutes).includes(location.pathname)
    );
  };

  return (
    <ConfigProvider
      renderEmpty={() => <NoData />}
      theme={{
        token: {
          fontFamily: 'Ubuntu, Roboto, Helvetica, Arial, sans-serif',
          colorBgLayout: '#ffffff',
          colorLink: '#0070c5',
          colorPrimary: '#0070c5',
        },
        components: {
          Layout: {
            headerHeight: 52,
            headerPadding: '0 0 0 18px',
            footerBg: '#2d2d2d',
            footerPadding: 8,
          },
          Tabs: {
            lineType: 'none', // to implement own bottom line on tabs
            itemSelectedColor: '#212121',
            itemColor: '#212121',
            itemHoverColor: '#0070c5',
            inkBarColor: isPageWithTab() ? 'transparent' : '#0070c5',
          },
          Card: {
            headerFontSize: 22,
            fontSize: 16,
          },
          List: {
            itemPadding: '4px 0px 4px 0px',
          },
          Tag: {
            defaultColor: '#212121',
          },
          Carousel: {
            dotHeight: 5,
            dotActiveWidth: 64,
            dotWidth: 20,
            arrowSize: 32,
            arrowOffset: -28,
          },
        },
      }}
    >
      {/* {toolBoxStore.deployMode === 'cloud' && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100px', // Match the height of the image
            overflow: 'hidden', // Hides the excessive parts of the image
            zIndex: 100000000000,
          }}
        >
          <a
            href={
              i18n.language === 'ru'
                ? 'https://nextgis.ru/blog/black-friday24/?utm_source=nextgis-toolbox&utm_medium=banner&utm_campaign=black-friday24&utm_content=ru'
                : 'https://nextgis.com/blog/black-friday24/?utm_source=nextgis-toolbox&utm_medium=banner&utm_campaign=black-friday24&utm_content=en'
            }
            target="_blank"
          >
            <img
              src={
                i18n.language === 'ru'
                  ? '/static/bf24_banner_2x_ru.webp'
                  : '/static/bf24_banner_2x_en.webp'
              }
              alt="black_friday"
              style={{
                height: '100px',
                position: 'absolute',
                // width: '100%',
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 100000000000,
              }}
            />
          </a>
        </div>
      )} */}

      <Layout
        style={{
          // minHeight: '100vh', // creepy but works for now
          // height: `calc(100% - ${toolBoxStore.deployMode === 'cloud' ? 156 : 56}px)`,
          height: `calc(100% - 56px)`,
        }}
      >
        <Header
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            // top: toolBoxStore.deployMode === 'cloud' ? 100 : 0,
            zIndex: 1000,
            display: 'flex',
            alignItems: 'flex-end',
            gap: '50px',
            backgroundRepeat: 'no-repeat',
            background: `linear-gradient(to bottom, #e5eef7 98%, #d3e3f2 2%)`,
          }}
        >
          <HeaderContent />
        </Header>
        <Content
          style={{
            // marginTop: `${toolBoxStore.deployMode === 'cloud' ? 152 : 52}px`,
            marginTop: '52px',
            // marginBottom:
            //   toolBoxStore.appConfig.deploy === 'cloud' ? '36px' : undefined,
            // marginBottom: '36px',
            height: '100%',
          }}
        >
          <RemoveTrailingSlash />
          <Routes>
            <Route
              path={appRoutes.mainPage}
              element={loading ? <SpinnerPage /> : <Main />}
            />
            <Route path={appRoutes.toolPage}>
              <Route path="" element={<Navigate to={appRoutes.mainPage} />} />
              <Route
                path=":toolId"
                element={
                  toolBoxStore.tools.length > 0 ? <ToolPage /> : <SpinnerPage />
                }
              />
            </Route>
            <Route path={appRoutes.operationOld}>
              <Route path="" element={<Navigate to={appRoutes.mainPage} />} />
              <Route path=":toolId" element={<RedirectToT />} />
            </Route>
            {toolBoxStore.deployMode === 'cloud' && (
              <>
                <Route path={appRoutes.aboutPage} element={<About />} />
                <Route path={appRoutes.pricingPage} element={<Pricing />} />
              </>
            )}
            <Route path={appRoutes.ordersPage} element={<ProtectedRoute />}>
              <Route path="" element={<Orders />} />
            </Route>
            <Route path="*" element={<NotFound />} />

            <Route path={'api/tools'}>
              <Route path="" element={<Navigate to={appRoutes.mainPage} />} />
              <Route
                path=":toolId"
                element={
                  toolBoxStore.tools.length > 0 ? <ToolPage /> : <SpinnerPage />
                }
              />
            </Route>
          </Routes>
        </Content>
        {toolBoxStore.appConfig.deploy === 'cloud' && (
          <Footer
            style={{
              color: 'rgba(255, 255, 255, 0.4)',
              zIndex: '10000',
              position: 'fixed',
              bottom: 0,
              width: '100%',
            }}
          >
            <FooterContent />
          </Footer>
        )}
      </Layout>
    </ConfigProvider>
  );
};

export default View;
