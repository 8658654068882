import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { toolBoxStore } from '../store/store';
import { openChatra } from '../utils/chatraService';

const AboutRu: React.FC = () => {
  //
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '0 10px 0 10px',
        }}
      >
        <div style={{ maxWidth: '900px', paddingTop: '40px', lineHeight: 1.6 }}>
          <h1 style={{ marginTop: 0, fontSize: 34 }}>О сервисе</h1>
          <div
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            <p>
              NextGIS Toolbox - постоянно пополняемый набор инструментов для
              обработки геоданных онлайн.
            </p>
            <p>
              Один запуск инструмента - это решение одной определенной задачи,
              как конвертация формата векторных данных или расчет спектрального
              альбедо. Некоторые инструменты ориентированы на интеграцию с Веб
              ГИС, другие работают с материалами Росреестра. Есть и
              узкоспециализированные инструменты, созданные для решения
              конкретной задачи на специфическом наборе данных.
            </p>

            <h4>Что нужно для работы?</h4>
            <p>
              Подписка на план{' '}
              <a href="https://nextgis.ru/pricing-base/?utm_source=nextgis&utm_medium=products&utm_campaign=nextgis-toolbox&utm_content=ru">
                Premium
              </a>
              . И ваш любимый браузер.
            </p>

            <h4>С чего начать?</h4>
            <ul>
              <li>
                <a href="https://toolbox.nextgis.com/">Подберите</a> подходящий
                инструмент, используя теги или строку поиска.
              </li>
              <li>
                Ознакомьтесь с инструкцией к инструменту, заполните необходимые
                поля и нажмите “Запустить”.
              </li>
              <li>
                Когда задача будет выполнена, на вашу почту придет уведомление.
                Вы также в любой момент можете узнать статус работы инструмента
                в разделе{' '}
                <a href="https://toolbox.nextgis.com/orders">“Мои задачи”</a>.
              </li>
            </ul>

            <p>
              Для всех инструментов также доступен запуск по API: обратитесь к
              вкладке “Python-код” на странице интересующего инструмента.
            </p>

            <h4>Помощь и поддержка</h4>
            <p>
              Проблемы при запуске инструмента? Напишите на{' '}
              <a href="mailto:support@nextgis.com">почту</a> или в{' '}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  if (toolBoxStore?.deployMode !== 'onpremise') {
                    openChatra();
                  }
                }}
              >
                чат
              </a>
              , наши специалисты обязательно вам помогут.
            </p>

            <p>
              <a href="https://www.youtube.com/playlist?list=PLTkGLTDK8v2rFN45cWw6cfik-_J2a_XQp">
                Цикл видео
              </a>{' '}
              об инструментах NextGIS Toolbox.
            </p>

            <p>
              Есть идея нового инструмента?{' '}
              <a href="mailto:info@nextgis.com">Напишите нам!</a>
            </p>
          </div>
        </div>
      </div>
      <a
        href="https://data.nextgis.com/ru/?utm_source=nextgis&utm_medium=products&utm_campaign=nextgis-toolbox&utm_content=ru"
        target="_blank"
        rel="noreferrer"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              maxWidth: '900px',
              width: 'auto',
            }}
          >
            <img
              style={{
                width: '70%',
                height: 'auto',
              }}
              src="/static/data_banner_ru.png"
              alt=""
            />
          </div>
        </div>
      </a>
      <pre>{`
      
      `}</pre>
    </>
  );
};

const AboutEn: React.FC = () => {
  //
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '0 10px 0 10px',
      }}
    >
      <div style={{ maxWidth: '900px', paddingTop: '40px', lineHeight: 1.6 }}>
        <h1 style={{ marginTop: 0, fontSize: 34 }}>About</h1>
        <div
          style={{
            fontSize: 16,
            fontWeight: 400,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          }}
        >
          <p>
            NextGIS Toolbox is the ever-growing collection of tools for online
            geodata processing.
          </p>
          <p>
            The service provides vast options, from conversion of data format to
            calculation of reflectance from TOA radiance. Some tools are meant
            to integrate with Web GIS, others work with OpenStreetMap data. Also
            there are a bunch of tools developed upon particular demand and they
            efficiently process specific data.
          </p>

          <h4>What do I need?</h4>
          <p>
            <a href="https://nextgis.com/pricing-base/?utm_source=nextgis&utm_medium=products&utm_campaign=nextgis-toolbox&utm_content=en">
              Premium
            </a>{' '}
            subscription. And your favorite browser.
          </p>

          <h4>Where to start?</h4>
          <ul>
            <li>
              <a href="https://toolbox.nextgis.com/">Find</a> the right tool for
              your request using tags or search bar.
            </li>
            <li>
              Explore the documentation for this tool, fill in required fields
              and click “Run”.
            </li>
            <li>
              Notification on completion will be delivered to your email. You
              can also check the status of the tool processing on the{' '}
              <a href="https://toolbox.nextgis.com/orders">“My Tasks”</a> page
              at any moment.
            </li>
          </ul>

          <p>
            Alternatively, you can run any tool via API: check the “Python-code”
            tab on the page of the target tool.
          </p>

          <h4>Feedback and support</h4>
          <p>
            Troubles with running tools? Contact us via{' '}
            <a href="mailto:support@nextgis.com">email</a> or{' '}
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                if (toolBoxStore?.deployMode !== 'onpremise') {
                  openChatra();
                }
              }}
            >
              chat
            </a>
            , our team is there to help you.
          </p>

          <p>
            NextGIS Toolbox{' '}
            <a href="https://www.youtube.com/playlist?list=PLg2roUY9ACxTDTXyrhWDmFX9e75pVzH7Q">
              playlist
            </a>
            .
          </p>

          <p>
            Have an idea about new tool?{' '}
            <a href="mailto:info@nextgis.com">Let us know!</a>
          </p>

          <a
            href="https://data.nextgis.com/en/?utm_source=nextgis&utm_medium=products&utm_campaign=nextgis-toolbox&utm_content=en"
            target="_blank"
            rel="noreferrer"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  maxWidth: '900px',
                  width: 'auto',
                }}
              >
                <img
                  style={{
                    width: '70%',
                    height: 'auto',
                  }}
                  src="/static/data_banner_en.png"
                  alt=""
                />
              </div>
            </div>
          </a>
          <pre>{`
          
          `}</pre>
        </div>
      </div>
    </div>
  );
};

const About: React.FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <meta property="og:title" content={t('og.about.title')} />
        <meta property="og:description" content={t('og.about.description')} />
        {/* <meta property="og:image" content="Your image URL" /> */}
      </Helmet>

      <div style={{ maxHeight: 'calc(100vh - 90px)', overflowY: 'auto' }}>
        {i18n.language === 'ru' ? <AboutRu /> : <AboutEn />}
      </div>
    </>
  );
};

export { About };
